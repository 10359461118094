<template>
  <div class="flex gap-x-3">
    <whats-app-btn
      v-if="hasWhatsappNumber"
      :button-class="buttonClass"
      :span-class="spanClass"
      :phone-number="messengers.whatsapp.phoneNumber"
    />
    <telegram-btn
      v-if="hasTelegramNumber"
      :button-class="buttonClass"
      :span-class="spanClass"
      :phone-number="messengers.telegram.phoneNumber"
      :login="messengers.telegram.username"
    />
  </div>
</template>

<script setup lang="ts">
import { MessengerTelegram } from '~/common/types/messengers/Messenger.telegram'
import { MessengerWhatsapp } from '~/common/types/messengers/Messenger.whatsapp'
import TelegramBtn from '~/components/buttons/TelegramBtn.vue'
import WhatsAppBtn from '~/components/buttons/WhatsAppBtn.vue'

type Messengers = {
    telegram: MessengerTelegram,
    whatsapp: MessengerWhatsapp
  }

const props = defineProps({
  messengers: {
    type: Object as PropType<Messengers>,
    required: true,
  },
  spanClass: {
    type: [String, Object, Array],
    default: '',
  },
  buttonClass: {
    type: [String, Object, Array],
    default: '',
  },
})

const hasWhatsappNumber = computed(() => props.messengers.whatsapp.phoneNumber)

const hasTelegramNumber = computed(() => props.messengers.telegram.phoneNumber || props.messengers.telegram.username)

</script>
